<template>
  <v-container>
    <v-card class="px-4 py-4 mb-4">
      <div class="d-flex">
        <NavButton class="mr-2 mb-4" />
        <h2 style="word-break: break-word">Ordenes de compra</h2>
      </div>

      <v-row align="center" justify="end">
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-text-field
            v-model="filters.codigo_orden"
            label="Código de Orden de compra"
            placeholder="Ingrese un código"
            hide-details
            clearable
            outlined
          />
        </v-col>

        <!-- <v-col cols="12" md="6" lg="6" xl="6">
          <v-select
            v-model="filters.estado"
            :items="estados.data"
            :loading="estados.isLoading"
            label="Estado de las ordenes de compra"
            placeholder="Seleccione un estado"
            item-value="id"
            item-text="nombre"
            hide-details
            outlined
            clearable
          />
        </v-col> -->
        
        <v-col cols="12" md="4" lg="4" xl="4">
          <v-autocomplete
            v-model="filters.id_institucion"
            :items="instituciones"
            label="Institución"
            placeholder="Seleccione una institución"
            item-value="id"
            item-text="nombre_completo"
            hide-details
            outlined
            clearable
          />
        </v-col>

        <v-col cols="12" md="4" lg="4" xl="4">
          <div class="d-flex align-center" style="gap: 8px">
            <v-btn
              @click.stop="limpiarSeguimientos"
              color="secondary"
              class="flex-grow-1 flex-shrink-1"
              x-large
            >
              Limpiar
            </v-btn>
            <v-btn
              @click.stop="buscarOrdenes"
              class="flex-grow-1 flex-shrink-1"
              color="primary"
              x-large
            >
              Buscar
            </v-btn>
          </div>
        </v-col>
      </v-row>
      
    </v-card>

    <v-card>
      <v-card-title>
        <v-row class="d-flex align-center justify-space-between w-100">
          <v-tabs
            v-model="tab"
            dark
            grow
            class="transparent-tabs flex-grow-1"
            @change="manejoTab"
          >
            <v-tab key="1" tab-value="1">Pendientes</v-tab>
            <v-tab key="2" tab-value="2">En Proceso</v-tab>
            <v-tab key="3" tab-value="3">Finalizadas</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" style="width: 100%">
            <v-tab-item key="1" value="1">
              <TabsOrdenesCompra
                :headers="headers"
                :items="ordenesPendientes.data"
                :total_registros="ordenesPendientes.pagination.total_rows"
                :is-loading="ordenesPendientes.isLoading"
                :paginar="manejarPaginacionRegistrosPendiente"
              />
            </v-tab-item>
            <v-tab-item key="2" value="2">
              <TabsOrdenesCompra
                :headers="headers"
                :items="ordenesEnProceso.data"
                :total_registros="ordenesEnProceso.pagination.total_rows"
                :is-loading="ordenesEnProceso.isLoading"
                :paginar="manejarPaginacionRegistrosEnProceso"
              />
            </v-tab-item>
            <v-tab-item key="3" value="3">
              <TabsOrdenesCompra
                :headers="headers"
                :items="ordenesFinalizadas.data"
                :total_registros="ordenesFinalizadas.pagination.total_rows"
                :is-loading="ordenesFinalizadas.isLoading"
                :paginar="manejarPaginacionRegistrosFinalizadas"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-row>
      </v-card-title>
    </v-card>
  </v-container>
</template>
<script>
import {
  createLoadable,
  createPageable,
  setLoadableResponse,
  setPageableResponse,
  toggleLoadable,
  togglePageable,
} from "@/utils/loadable.js";
import { EmptyComponent, SpinnerComponent } from "@/components/utils";
import { NavButton } from "@/components/utils";
import DataTableComponent from "@/components/DataTableComponent.vue";
import TiempoEntrega from "./components/TiempoEntrega.vue";
import TabsOrdenesCompra from "../CMCumplimientoSolicitudes/TabsOrdenesCompra.vue";

const filtrosIniciales = {
  codigo_orden: null,
  estado: null,
  id_institucion: null,
};

export default {
  components: {
    DataTableComponent,
    EmptyComponent,
    SpinnerComponent,
    NavButton,
    TiempoEntrega,
    TabsOrdenesCompra,
  },
  data: () => ({
    tab: "1",
    filters: { ...filtrosIniciales },
    ordenSeleccionada: null,
    estados: createLoadable([]),
    ctlInstituciones: [],
    headers: [
      { align: 'center', sortable: false, value: 'codigo', text: 'Orden de compra' },
      { align: 'center', sortable: false, value: 'institucion', text: 'Institucion' },
      { align: 'center', sortable: false, value: 'tiempo_restante', text: 'Tiempo restante' },
      { align: 'center', sortable: false, value: 'fecha_autorizacion', text: 'Fecha de creación' },
      { align: 'center', sortable: false, value: 'total', text: 'Total Inicial'},
      { align: 'center', sortable: false, value: 'total_aceptado', text: 'Total Aceptado' },
      { align: 'center', sortable: false, value: 'estado', text: 'Estado' },
      { align: 'center', sortable: false, value: 'acciones', text: 'Acciones' },
    ],
    ordenesPendientes: createPageable([]),
    ordenesEnProceso: createPageable([]),
    ordenesFinalizadas: createPageable([]),
    paginacionOrdenesPendientes: {
      paginaActual: 1,
      registrosPorPagina: 10,
    },
    paginacionOrdenesEnProcesos: {
      paginaActual: 1,
      registrosPorPagina: 10,
    },
    paginacionOrdenesFinalizadas: {
      paginaActual: 1,
      registrosPorPagina: 10,
    },
  }),
  computed: {
    instituciones() {
      return this.ctlInstituciones.map((institucion) => ({
        ...institucion,
        nombre_completo: `${institucion.codigo} - ${institucion.nombre}`,
      }));
    },
  },
  methods: {
    async cargarOrdenesPoEstado(estado, loadable, paginacion) {
      togglePageable(loadable);
      const filtros = {
        pagination: true,
        per_page: paginacion.registrosPorPagina,
        page: paginacion.paginaActual,
        id_estado: estado,
        ...this.filters,
      };
      const { data, headers } =
        await this.services.cmOrdenesCompra.cargarListaOrdenesCompra(filtros);
      setPageableResponse(loadable, data, headers);
    },
    async cargarOrdenesCompra() {
      // Ordenes Pendientes
     await this.cargarOrdenesPoEstado(
        1,
        this.ordenesPendientes,
        this.paginacionOrdenesPendientes
      );
      // Ordenes En Proceso
      await this.cargarOrdenesPoEstado(
        2,
        this.ordenesEnProceso,
        this.paginacionOrdenesEnProcesos
      );
      // Ordenes Finalizadas
      await this.cargarOrdenesPoEstado(
        3,
        this.ordenesFinalizadas,
        this.paginacionOrdenesFinalizadas
      );
    },
    async obtenerEstados() {
      toggleLoadable(this.estados);
      const filtros = {
        tab: this.tab,
      };
      const { data } = await this.services.cmOrdenesCompra.obtenerEstadoOrden(
        filtros
      );
      setLoadableResponse(this.estados, data);
    },
    manejoTab() {
      this.paginaActual = 1;
      this.cargarOrdenesCompra();
    },
    limpiarSeguimientos() {
      this.filters = { ...filtrosIniciales };
      this.pagina = 1;
      this.tab = 1;
    },
    async fetchInstituciones() {
      const response =
        await this.services.Instituciones.obtenerCatalogoInstituciones({
          pagination: false,
        });
      if (response) {
        this.ctlInstituciones = response.data;
      }
    },
    async buscarOrdenes() {
      this.pagina = 1;
      await this.cargarOrdenesCompra();
      const ordenesPendientesCount = this.ordenesPendientes.data.length;
      const ordenesEnProcesoCount = this.ordenesEnProceso.data.length;
      const ordenesFinalizadasCount = this.ordenesFinalizadas.data.length;

      if (ordenesPendientesCount > 0) {
        this.tab = "1";
        return;
      }

      if (ordenesEnProcesoCount > 0) {
        this.tab = "2";
        return;
      }

      if (ordenesFinalizadasCount > 0) {
        this.tab = "3";
        return;
      }
    },
    manejarPaginacionRegistrosPendiente(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginacionOrdenesPendientes.paginaActual = pagina;
      this.paginacionOrdenesPendientes.registrosPorPagina = cantidad_por_pagina;
      this.cargarOrdenesCompra();
    },
    manejarPaginacionRegistrosEnProceso(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginacionOrdenesEnProcesos.paginaActual = pagina;
      this.paginacionOrdenesEnProcesos.registrosPorPagina = cantidad_por_pagina;
      this.cargarOrdenesCompra();
    },
    manejarPaginacionRegistrosFinalizadas(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginacionOrdenesFinalizadas.paginaActual = pagina;
      this.paginacionOrdenesFinalizadas.registrosPorPagina =
        cantidad_por_pagina;
      this.cargarOrdenesCompra();
    },
  },
  created() {
    this.cargarOrdenesCompra();
    this.obtenerEstados();
    this.fetchInstituciones();
  },
  watch: {
    tab: {
      handler() {
        this.obtenerEstados();
      },
    },
  },
};
</script>

<style>
.transparent-tabs .v-tabs-bar,
.transparent-tabs .v-tabs-slider {
  background-color: transparent !important;
  color: black !important;
}

.transparent-tabs .v-tab {
  background-color: transparent !important;
  color: black !important;
}
</style>
